define("discourse/plugins/discourse-new-features-feeds/discourse/initializers/discourse-new-features-feeds-admin-plugin-configuration-nav", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-new-features-feeds-admin-plugin-configuration-nav",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      if (!currentUser?.admin) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.1.0", api => {
        api.addAdminPluginConfigurationNav("discourse-new-features-feeds", [{
          label: "new_feature_feeds.header",
          route: "adminPlugins.show.discourse-new-features-feeds"
        }]);
      });
    }
  };
});